const DEBUG_AB_TEST_PARAM_NAME = "debug-ab-test-name";
// AB TESTS
const PRICE_PER_USER_AB_TEST = "homepage_price_per_user_ab_test";
const NEW_COOKIES_POLICY_AB_TEST = "new_cookies_policy_ab_test";
const SOFT_SIGNUP_TRIAL_CTA_HOMEPAGE_AB_TEST = "soft_signup_trial_cta_homepage_ab_test";
const SOFT_SIGNUP_TRIAL_CTA_LP_AB_TEST = "soft_signup_trial_cta_lp_ab_test";
const SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST = "skip_sign_up_step_in_pricing_page_ab_test";
const HOMEPAGE_PRICE_PER_SEAT_NEW_AB_TEST = "homepage_price_per_seat_new_ab_test";
const EXIT_POP_UP_SURVEY_AB_TEST = "exit_pop_up_survey_ab_test";
const TRY_FOR_FREE_CTA_AB_TEST = "try_for_free_cta_ab_test";
const HOMEPAGE_PRICE_PER_SEAT_V3_AB_TEST = "homepage_price_per_seat_v3_ab_test";
const REVIVE_EXIT_POPUP_AB_TEST = {
    test_name: "revive_exit_popup_ab_test",
    new_variation_name: "new_with_exit_popup"
};
const PERFORMANCE_STATIC_PAGE_AB_TEST = {
    test_name: "performance_static_page_ab_test",
    new_variation_name: "new_static_page"
};
const BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES = {
    test_name: "homepage_bigger_storage_in_pricing_page_features",
    new_variation_name: "new_with_bigger_storage"
};
const HOMEPAGE_V10_PRICING_VERSION_AB_TEST = {
    test_name: "homepage_v10_pricing_version_ab_test",
    new_variation_name: "new_with_v10"
};
const HOMEPAGE_V12_PRICING_VERSION_AB_TEST = {
    test_name: "homepage_v12_pricing_version_ab_test",
    new_variation_name: "new_with_v12"
};
const HOMEPAGE_V13_PRICING_VERSION_AB_TEST = {
    test_name: "homepage_v13_pricing_version_ab_test",
    new_variation_name: "new_with_v13"
};
const HOMEPAGE_FREE_TIER_2022_ABC_TEST = {
    test_name: "homepage_free_tier_2022_ab_test",
    new_variation_name_b: "new_with_free_tier_2022_a",
    new_variation_name_c: "new_with_free_tier_2022_b"
};
const DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST = {
    test_name: "desktop_with_enterprise_in_submenu_ab_test",
    new_variation_name: "new_with_enterprise_in_submenu"
};
const HOMEPAGE_HIDE_BASIC_AB_TEST = {
    test_name: "homepage_hide_basic_ab_test",
    new_variation_name: "new_with_hide_basic"
};
const HOMEPAGE_PRODUCT_SOLUTIONS_AB_TEST = {
    test_name: "homepage_product_solutions_ab_test",
    new_variation_name: "new_with_product_solutions"
};
const PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST = {
    test_name: "pricing_enterprise_contact_sales_ab_test",
    new_variation_name: "new_enterprise_page"
};
const MOBILE_STICKY_SIGNUP_AB_TEST = {
    test_name: "mobile_sticky_signup_ab_test",
    new_variation_name: "new_with_sticky_signup"
};
const WELCOME_BACK_PAGE_TEST = {
    test_name: "welcome_back_account_homepage_return_to_account_cta_test_2",
    new_variation_name: "new_with_welcome_back",
    launch_date: "2022-11-08"
};
const WEBSITE_EXISTING_ACCOUNT_SWITCHER_HEADER_AB_TEST = {
    test_name: "website_existing_account_contact_sales_switcher_test",
    new_variation_name: "new_existing_account_contact_sales_switcher_test"
};
const KEEP_REGION_TEST = {
    test_name: "keep_region_au_ab_test",
    new_variation_name: "new_with_keep_region"
};
const CONTACT_SALES_TYPEFORM_EMEA = {
    test_name: "contact_sales_typeform_emea",
    new_variation_name: "new_with_typeform"
};
const CONTACT_SALES_TYPEFORM_ANZ = {
    test_name: "contact_sales_typeform_anz",
    new_variation_name: "new_with_typeform"
};
const HEADER_NEW_PRODUCTS_OFFERING_TEST = {
    test_name: "header_new_products_offering_more_clickable_test",
    new_variation_name: "new_header_products_offering"
};
const UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST = {
    test_name: "unlimited_time_free_plan_cta_text_test",
    new_variation_name: "new_unlimited_time_cta"
};
const TYPEFORM_FIRST_SCREEN_MULTIPLE_FIELDS_TEST = {
    test_name: "typeform_first_screen_multiple_fields_test",
    new_variation_name: "new_with_multiple_fields"
};
const STUDIO_IN_HEADER_TEST = {
    test_name: "studio_in_header",
    new_variation_name: "new_studio_in_header"
};
const ENHANCED_PRODUCTS_IN_HEADER_TEST = {
    test_name: "enhanced_products_in_header",
    new_variation_name: "new_with_enhanced_products_in_header"
};
const WM_HIGH_INTENT_EXPERIENCE = {
    test_name: "wm_high_intent_experience_test",
    new_variation_name: "new_with_high_intent_experience"
};
const CONTACT_SALES_FOOTER_TEST = {
    test_name: "contact_sales_footer_test",
    new_variation_name: "new_contact_sales_footer"
};
const BOOK_A_MEETING_EMAIL_TEST = {
    test_name: "book_a_meeting_email_test",
    new_variation_name: "new_book_a_meeting_email"
};
const GLOVE_HEADER_SCRIPT_TEST = {
    test_name: "glove_header_script_test",
    new_variation_name: "with_glove_header_script"
};
const DACH_GET_STARTED_BUTTON_DESCRIPTION_TEXT_TEST = {
    test_name: "dach_get_started_button_description_text_test_02",
    new_variation_name: "new_dach_get_started_button_description_text_test_02"
};
const ENTERPRISE_BUTTON_IN_HEADER_TEST = {
    test_name: "enterprise_button_in_header_test",
    new_variation_name: "new_enterprise_button_in_header"
};
const CX_BOT_TEST = {
    test_name: "cx_bot_test",
    new_variation_name: "new_cx_bot_test"
};
module.exports = {
    DEBUG_AB_TEST_PARAM_NAME,
    PRICE_PER_USER_AB_TEST,
    NEW_COOKIES_POLICY_AB_TEST,
    SOFT_SIGNUP_TRIAL_CTA_HOMEPAGE_AB_TEST,
    SOFT_SIGNUP_TRIAL_CTA_LP_AB_TEST,
    SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST,
    HOMEPAGE_PRICE_PER_SEAT_NEW_AB_TEST,
    EXIT_POP_UP_SURVEY_AB_TEST,
    HOMEPAGE_PRICE_PER_SEAT_V3_AB_TEST,
    TRY_FOR_FREE_CTA_AB_TEST,
    REVIVE_EXIT_POPUP_AB_TEST,
    PERFORMANCE_STATIC_PAGE_AB_TEST,
    BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES,
    HOMEPAGE_V10_PRICING_VERSION_AB_TEST,
    HOMEPAGE_V12_PRICING_VERSION_AB_TEST,
    HOMEPAGE_V13_PRICING_VERSION_AB_TEST,
    HOMEPAGE_FREE_TIER_2022_ABC_TEST,
    DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST,
    HOMEPAGE_HIDE_BASIC_AB_TEST,
    HOMEPAGE_PRODUCT_SOLUTIONS_AB_TEST,
    PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST,
    MOBILE_STICKY_SIGNUP_AB_TEST,
    WELCOME_BACK_PAGE_TEST,
    KEEP_REGION_TEST,
    CONTACT_SALES_TYPEFORM_EMEA,
    CONTACT_SALES_TYPEFORM_ANZ,
    HEADER_NEW_PRODUCTS_OFFERING_TEST,
    UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST,
    TYPEFORM_FIRST_SCREEN_MULTIPLE_FIELDS_TEST,
    STUDIO_IN_HEADER_TEST,
    ENHANCED_PRODUCTS_IN_HEADER_TEST,
    WM_HIGH_INTENT_EXPERIENCE,
    CONTACT_SALES_FOOTER_TEST,
    WEBSITE_EXISTING_ACCOUNT_SWITCHER_HEADER_AB_TEST,
    GLOVE_HEADER_SCRIPT_TEST,
    DACH_GET_STARTED_BUTTON_DESCRIPTION_TEXT_TEST,
    BOOK_A_MEETING_EMAIL_TEST,
    CX_BOT_TEST,
    ENTERPRISE_BUTTON_IN_HEADER_TEST
};
